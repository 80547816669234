button::-moz-focus-inner,
input[type=button]::-moz-focus-inner,
input[type=file]>input[type=button]::-moz-focus-inner,
input[type=reset]::-moz-focus-inner,
input[type=submit]::-moz-focus-inner {
    border: none
}

.iu-button-container {
    display: inline-block;
    width: 100%;
    text-align: center
}

.iu-button-container .iu-button-button {
    box-sizing: content-box;
    padding: 0;
    width: 100%;
    height: 100%;
    outline: none;
    border: none;
    background: transparent;
    box-shadow: none;
    color: currentColor;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
    -webkit-transition: background-color .3s, width .3s, border-width .3s, border-color .3s, border-radius .3s;
    transition: background-color .3s, width .3s, border-width .3s, border-color .3s, border-radius .3s;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -webkit-tap-highlight-color: transparent
}

.iu-button-container .iu-button-button span {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center
}

.iu-button-container .iu-button-button>span {
    display: inherit;
    font-weight: 100;
    font-size: 2em;
    -webkit-transition: opacity .3s .1s;
    transition: opacity .3s .1s
}

.iu-button-container .iu-button-button>svg {
    position: absolute;
    width: 54px;
    height: 54px;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    pointer-events: none
}

.iu-button-container .iu-button-button>svg path {
    opacity: 0;
    fill: none
}

.iu-button-container .iu-button-button svg.iu-button-progress-circle {
    -webkit-animation: a .9s infinite cubic-bezier(.085, .26, .935, .71);
    animation: a .9s infinite cubic-bezier(.085, .26, .935, .71)
}

.iu-button-container .iu-button-button svg.iu-button-progress-circle path {
    stroke: currentColor;
    stroke-width: 5
}

.iu-button-container .iu-button-button svg.iu-button-checkmark path,
.iu-button-container .iu-button-button svg.iu-button-cross path {
    stroke: #fff;
    stroke-linecap: round;
    stroke-width: 4
}

.iu-button-container.disabled .iu-button-button {
    cursor: not-allowed
}

.iu-button-container.loading .iu-button-button {
    padding: 0;
    width: 54px;
    border-width: 6.5px;
    border-color: #ddd;
    background-color: transparent;
    color: currentColor;
    cursor: wait
}

.iu-button-container.loading .iu-button-button span {
    display: none;
    opacity: 0;
    -webkit-transition: all .15s;
    transition: all .15s
}

.iu-button-container.loading .iu-button-button .iu-button-progress-circle>path {
    opacity: 1;
    -webkit-transition: opacity .15s .3s;
    transition: opacity .15s .3s
}

.iu-button-container.success .iu-button-button {
    border-color: #a0d468;
    background-color: #a0d468
}

.iu-button-container.success .iu-button-button span {
    display: none;
    opacity: 0;
    -webkit-transition: all .15s;
    transition: all .15s
}

.iu-button-container.success .iu-button-button .iu-button-checkmark>path {
    opacity: 1
}

.iu-button-container.error .iu-button-button {
    border-color: #ed5565;
    background-color: #ed5565
}

.iu-button-container.error .iu-button-button span {
    display: none;
    opacity: 0;
    -webkit-transition: all .15s;
    transition: all .15s
}

.iu-button-container.error .iu-button-button .iu-button-cross>path {
    opacity: 1
}

.iu-button-container.error,
.iu-button-container.loading,
.iu-button-container.success {
    width: 100% !important;
    height: 100% !important
}

@-webkit-keyframes a {
    0% {
        -webkit-transform: translate(-50%, -50%) rotate(0deg);
        transform: translate(-50%, -50%) rotate(0deg);
        -webkit-transform-origin: center center;
        transform-origin: center center
    }

    to {
        -webkit-transform: translate(-50%, -50%) rotate(1turn);
        transform: translate(-50%, -50%) rotate(1turn);
        -webkit-transform-origin: center center;
        transform-origin: center center
    }
}

@keyframes a {
    0% {
        -webkit-transform: translate(-50%, -50%) rotate(0deg);
        transform: translate(-50%, -50%) rotate(0deg);
        -webkit-transform-origin: center center;
        transform-origin: center center
    }

    to {
        -webkit-transform: translate(-50%, -50%) rotate(1turn);
        transform: translate(-50%, -50%) rotate(1turn);
        -webkit-transform-origin: center center;
        transform-origin: center center
    }
}

.iu-container,
.iu-container * {
    box-sizing: border-box
}

.iu-container * {
    outline: none
}

.iu-container.disabled .iu-loadContainer {
    cursor: not-allowed
}

.iu-container.disabled .iu-loadContainer .iu-dropzone {
    background: transparent !important
}

.iu-container.disabled .iu-deletePreview,
.iu-container.disabled .iu-label,
.iu-container.disabled .iu-loadContainer .iu-pseudobutton * {
    cursor: not-allowed
}

.iu-imgPreview {
    position: relative;
    display: inline-block;
    margin: 5px 10px 5px 0;
    width: 100px;
    height: 100px;
    border: 1px solid #bec3c7;
    border-radius: 10px;
    background-position: 50%;
    background-size: cover;
    background-repeat: no-repeat;
    vertical-align: bottom
}

.iu-deletePreview {
    position: absolute;
    top: 2px;
    right: 2px;
    z-index: 1;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    padding: 4px;
    border: 1px solid #bec3c7;
    border-radius: 50%;
    background: #fff;
    font-size: medium;
    cursor: pointer
}

.iu-fileInput {
    display: inline-block
}

.iu-filesInputContainer {
    display: inline-block;
    margin: 5px 10px 5px 0;
    vertical-align: bottom
}

.iu-loadContainer {
    position: relative;
    overflow: hidden;
    width: 100px;
    height: 100px;
    border-radius: 10px;
    cursor: pointer
}

.iu-pseudobutton span {
    height: 100%
}

.iu-pseudobutton span .iu-imgPreview {
    margin: 0
}

.iu-dropzone {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 100% !important;
    height: 100% !important;
    border-width: 1px !important;
    border-style: solid !important;
    border-radius: 10px !important
}

.iu-dropzone:hover {
    background-color: #eee
}

.iu-label {
    display: block;
    padding-bottom: 5px;
    font-size: 12px;
    cursor: pointer
}

.iu-pseudobutton {
    margin-top: 0;
    width: 100px;
    height: 100px
}

.iu-pseudobuttonContent {
    line-height: 1 !important
}

.iu-notification {
    position: absolute;
    bottom: 0;
    left: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .3)
}
